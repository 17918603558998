import React, { useState } from 'react';
import styled from 'styled-components'
import { useLottie } from "lottie-react";
import StyledLink from './styledLink'
import { Link } from "gatsby"

import magnetAnimation from '../animations/magnet.json'
import arrowAnimation from '../animations/arrow.json'
import phoneAnimation from '../animations/phone.json'
import euroAnimation from '../animations/euro.json'


const ServiceWrapper = styled.div`
    background: white;
    max-width: 900px;
    margin-right: auto;
    margin-left: auto;

    p {
        font-family: Courier New, Courier, Lucida Sans Typewriter, Lucida Typewriter, monospace;
        font-size: 17px;
        font-style: normal;
        font-variant: normal;
        font-weight: 400;
        line-height: 23px;
        margin-right: 20px;
        margin-left: 20px;
        margin-bottom: 30px;
        font-weight: bold;
        color: #686868;
        text-align: center;
    }
`

const ServicesGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 15px;
    grid-row-gap: 20px; 

    @media screen and (min-width: 1000px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
`

const ServiceIcon = styled.div`
    margin: 0 auto;
    background: white;
    margin-bottom: 20px;

    svg {
        max-width: 100px;
        width: 90%!important;
        display: block;
        margin-left: auto;
        margin-right: auto;
        cursor: pointer;
    }

    h2 {
        text-align: center;
    }

    h3 {
        font-size: 1rem;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 20px;
        color: black;
    }
`

const Icon = (props) => {

    const [ isPlaying, setIsPlaying ] = useState(false);

    const options = {
        animationData: props.animationData,
        loop: isPlaying ? true : false,
        autoplay: isPlaying ? true : false
    }

    const { View } = useLottie(options)
    const playIconAnimation  = (props) => {
        setIsPlaying(true)
    }

    const stopIconAnimation = (props) => {
        setIsPlaying(false)
    }

    return(

        <ServiceIcon onMouseEnter={playIconAnimation} onMouseLeave={stopIconAnimation}>
            {View}
            <h3>{props.iconTitle}</h3>
        </ServiceIcon>
    )
}


const Services = (props) => {
    return(

        <ServiceWrapper>
            <h2>Animation Services</h2>
            <ServicesGrid>
                <Link to={'/animation-services'}><Icon iconTitle={'Attract Customers'} animationData={magnetAnimation} /></Link>
                <Link to={'/animation-services'}><Icon iconTitle={'Targeted Ads'} animationData={arrowAnimation} /></Link>
                <Link to={'/animation-services'}><Icon iconTitle={'Increase Sales'} animationData={euroAnimation} /></Link>
                <Link to={'/animation-services'}><Icon iconTitle={'Platform Independent Ads'} animationData={phoneAnimation} /></Link>
            </ServicesGrid>
            <p>Fizzpack is an animated video production company! We specialize in animated explainer videos, demo videos, corporate videos and marketing videos for your business. We produce video that makes the biggest impact on instagram, facebook, snapchat, youtube and other social platforms.</p>
            <StyledLink to="animation-services">Learn More</StyledLink>
        </ServiceWrapper>
    )
}

export default Services;
import React, { useState } from 'react'
import styled from 'styled-components'
import 'animate.css'

import fizzpackLogo from '../images/fizzpack.svg'
import squiggles from '../images/squiggles.svg'
import playButton from '../images/play.svg'
import bgImage from '../images/bg-items.svg'

const HeroWrapper = styled.div`
    text-align: center;
    color: white;
    background-image: url(${bgImage}), linear-gradient(160deg, #f0a354, #e43f35 50%, #c8409e);
    background-size: cover;
    background-repeat: repeat-y;
    padding-top: 113px;
    padding-bottom: 113px;
    margin-top: -113px;
    background-position: bottom;

    h1 {
        font-size: 2.6em;
    }

    .hero-image {
        display: block;
        margin: 20px auto;
        width: 70%;
        max-width: 1000px;
    }

    .hint {
        font-family: Courier New, Courier, Lucida Sans Typewriter, Lucida Typewriter, monospace;
        color: black;
        font-weight: bold;
    }

    .hero-squiggles {
        display: block;
        margin: 5px auto;
        max-width: 30% 
    }

    .hero-play-button {
        width: 70px;
        background: transparent;
        border: none;
    }

    .hero-play-button:hover {
        cursor: pointer;
        transform: scale(1.2);
        transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }

    &::after {
    /* Hack to fix glitchy line appearing under background-image */
    content: "";
    display: block;
    background: #fff;
    height: 20px;
    top: 117px;
    position: relative;

}
`

const ResponiveVideoContainer = styled.div`

    cursor: pointer;

    .toggled-off {
        position: fixed;
        transition: all  0.32s;
    }

    .toggled-on {
        top: 0;
        transition: all 0.32s;
    }

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        margin: auto;
        max-width: 1200px;
    }

    .hero-play {
        max-width: 100px;
        margin-top: 20px;
    }

    &.blackout-on {
        background: rgba(0,0,0,0.85);
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: none;
        margin: 0 auto;
        z-index: 20;       
        overflow-y: hidden;
    }
`

const CloseButton = styled.div`

    position: absolute;
    right: 32px;
    top: 32px;
    width: 32px;
    height: 32px;
    visibility: hidden;
    z-index: 100;
    cursor: pointer;
    
    &.toggled-on {
        visibility: visible;
    }

    &:before, &:after {
        position: absolute;
        content: '';
        background-color: white;
        top: 0;
        height: 32px;
        left: 15px;
        width: 5px;
        display: block;
        transform: rotate(45deg);
    }

    &:after {
        transform: rotate(-45deg);
    }

    &:hover, &:focus {
        opacity: 0.6;
    }

`

const HeroBanner = (props) => {

    const [isVideoOpen, setIsVideoOpen] = useState()

    const toggleVideoModal = (e) => {
        e.preventDefault()
        setIsVideoOpen(!isVideoOpen)
    }

    if (typeof window !== 'undefined') {
        window.addEventListener('keydown', (e) => {
        
            if (e.key === 'Escape') {
                const isNotShortCut = !(e.ctrlKey || e.altKey || e.shiftKey);
                if (isNotShortCut) {
                    e.preventDefault()
                    setIsVideoOpen(!isVideoOpen)
                }
            }
        })
    }


    return(

        <HeroWrapper>

            <img className="hero-image animate__animated animate__bounceIn" src={fizzpackLogo} alt="fizzpack logo" />

            <h1>Animated Advertising Shorts</h1>

                    <CloseButton className={isVideoOpen ? "toggled-on" : "toggled-off"} onClick={ toggleVideoModal }/>

                    <ResponiveVideoContainer className={isVideoOpen ? "video-wrapper blackout-on" : "video-wrapper blackout-off"} onClick={ toggleVideoModal } >

                        <div className={isVideoOpen ? "toggled-on" : "toggled-off"}  >
                            
                            <iframe title="Fizzpack Reel" src="https://player.vimeo.com/video/447435118?title=0" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                        </div>
                        
                    </ResponiveVideoContainer><script src="https://player.vimeo.com/api/player.js"></script>        


            <img className="hero-play-button" src={playButton} alt="play button" onClick={ toggleVideoModal }/>

            <p className="hint">Show Video</p>

            <img className="hero-squiggles" src={squiggles} alt="decorative squiggles" />

        </HeroWrapper>
    )
}

export default HeroBanner;
import styled from 'styled-components'
import { Link } from "gatsby"

const StyledLink = styled(Link)`
font-size: 24px;
background: ${props => props.bgcolor || "#f60057"};
color: ${props => props.textcolor || "#fff"};
padding: 20px 30px;
border-radius: 100px;
transform: translateY(10px);
display: block;
max-width: 200px;
margin: 0 auto;
position: relative;
text-align: center;
z-index: 3;

    &:hover, 
    &:focus {
        transform: scale(1.2) translateY(8px);
        transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
`

export default StyledLink;
import React from 'react';
import styled from 'styled-components'
import StyledLink from './styledLink'

import computers from '../images/computers.png'
import mobile from '../images/mobile.png'
import bg from '../images/bg-rough-top.svg'


const FurtherInfoWrapper = styled.div`
    text-align: center;
    color: white;
    background-image: url(${bg}), linear-gradient(160deg, #c300ff, #e43f35 50%, #c8409e);
    background-size: cover;
    background-repeat: repeat-y;
    padding-top: 226px;
    padding-bottom: 113px;
    background-position: top;

    .further-info-computer {
        width: 95%;
        margin: 5px auto;
        max-width: 1200px;
    }

    .further-info-computer-mobile {
        display: none;
    }

    h2 {
        font-size: 2.2rem;
    }

    &::before {
        /* Hack to fix glitchy line appearing under background-image */
        content: "";
        display: block;
        background: #fff;
        height: 20px;
        top: -243px;
        position: relative;
    }
`

const FurtherInfo = (props) => {
    return(

    <FurtherInfoWrapper>
        <h2>Grow Your Business: Video Ads That Convert</h2>
        <img className="further-info-computer" src={computers} alt="computer with text" />
        <img className="further-info-computer-mobile" src= {mobile} alt="computer with text" />
        <StyledLink to="/contact-us" bgcolor={'#fff'} textcolor={'#f60057'}>Get in touch</StyledLink>
    </FurtherInfoWrapper>

    )
}

export default FurtherInfo;
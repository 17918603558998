import * as React from "react"
import Menu from '../components/menu'
import HeroBanner from '../components/hero-banner'
import Services from '../components/services'
import FurtherInfo from '../components/further-info'
import Footer from '../components/footer'
import HelmetInfo from '../components/helmet-info'



const IndexPage = () => {
  return (

    <>
    <HelmetInfo />
    <Menu/>
    <main>
      <HeroBanner />
      <Services />
      <FurtherInfo />
    </main>
    <Footer/>
    </>
  )
}

export default IndexPage
